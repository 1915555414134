import React, { useState, useEffect } from 'react';
import './demandas.css'
import Sidebar from '../../components/SideBar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from "../../auth/firebaseConnection";
import { collection, addDoc, doc, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';


const Formulario = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [ged, setGed] = useState('');
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataTermino, setDataTermino] = useState(new Date());
  const [objeto, setObjeto] = useState('');
  const [contrato, setContrato] = useState('');
  const [horas, setHoras] = useState('');
  const [valorHora, setValorHora] = useState('');
  const [valorTotal, setValorTotal] = useState('');
  const [imposto, setImposto] = useState('Teresina');
  const [aReceber, setAReceber] = useState('');
  const [nFiscal, setNfiscal] = useState('');
  const [status, setStatus] = useState('');


  const handleImpostoChange = (event) => {
    setImposto(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const dadosParaSalvar = {
      dataInicio,
      dataTermino,
      ged,
      objeto,
      contrato,
      horas,
      valorHora,
      valorTotal,
      imposto,
      aReceber,
      nFiscal,
      status,
    };

    try {
      const demandasRef = doc(db, 'demandas', email);

      const novaDemandasRef = collection(demandasRef, 'subcolecoes');
      await addDoc(novaDemandasRef, dadosParaSalvar);

      toast.success('Enviado com sucesso!', { className: 'toast-css' });
    } catch (error) {
      console.error('Erro ao adicionar documento ao Firebase:', error);
      toast.error('Erro ao enviar', { className: 'toast-css' });
    }

    clearForm();
  };


  const clearForm = () => {
    setEmail('');
    setGed('');
    setDataInicio(new Date());
    setDataTermino(new Date());
    setObjeto('');
    setContrato('');
    setHoras('');
    setValorHora('');
    setValorTotal('');
    setImposto('Teresina');
    setAReceber('');
    setNfiscal('');
    setStatus('');
  };

  useEffect(() => {
    const calcularValorTotal = () => {
      const totalFloat = parseFloat(valorTotal);
      const horasFloat = parseFloat(horas);
  
      if (!isNaN(totalFloat) && !isNaN(horasFloat) && horasFloat !== 0) {
        const valorHoraCalculado = totalFloat / horasFloat;
        setValorHora(valorHoraCalculado.toFixed(2));
  
        let porcentagem = 0.3;
  
        if (imposto === 'viagem') {
          porcentagem = 0.25;
        } else if (imposto === 'viagem2') {
          porcentagem = 0.2;
        }
        const valorImposto = totalFloat * porcentagem;
        const aReceberCalculado = totalFloat - valorImposto;
        setAReceber(aReceberCalculado.toFixed(2));
      } else {
        setValorHora('');
        setAReceber('');
      }
    };
  
    calcularValorTotal();
  }, [valorTotal, horas, imposto]);
  
  useEffect(() => {
    const fetchData = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const emailsArray = usersSnapshot.docs.map(doc => doc.data().email);
      setEmails(emailsArray);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className='container'>
        <div className="formulario-container">
          <h2>Cadastrar Demanda</h2>
          <form onSubmit={handleSubmit}>
            <div className="date-picker-container">
              <div className='data-datapicker'>
                <div>
                  <label>Email:</label>
                  <select type="email" value={email} onChange={(e) => setEmail(e.target.value)} required >
                    <option></option>
                    {emails.map((email, index) => (
                      <option key={index}>{email}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>GED</label>
                  <input type="text" value={ged} onChange={(e) => setGed(e.target.value)} />
                </div>

                <div>
                  <label>NF</label>
                  <input type="text" value={nFiscal} onChange={(e) => setNfiscal(e.target.value)} />
                </div>
              </div>

              <div className='data-datapicker'>
                <div>
                  <label>Data Início:</label>
                  <DatePicker selected={dataInicio} onChange={(date) => setDataInicio(date)} dateFormat="dd/MM/yyyy" />
                </div>
                <div>
                  <label>Data Término:</label>
                  <DatePicker selected={dataTermino} onChange={(date) => setDataTermino(date)} dateFormat="dd/MM/yyyy" />
                </div>
              </div>
            </div>

            <div>
              <label>Objeto da contratação</label>
              <input type="text" value={objeto} onChange={(e) => setObjeto(e.target.value)} required />
            </div>

            <div>
              <label>Numero do Contrato</label>
              <input type="text" value={contrato} onChange={(e) => setContrato(e.target.value)} required />
            </div>

            <div>
              <label>Quantidade de Horas</label>
              <input type="number" value={horas} onChange={(e) => setHoras(e.target.value)} required />
            </div>

            <div>
              <label>Valor Hora</label>
              <input type="number" value={valorHora} readOnly />
            </div>

            <div>
              <label>Valor Total</label>
              <input type="text" value={valorTotal} onChange={(e) => setValorTotal(e.target.value)} required />
            </div>

            <div>
              <label>Imposto</label>
              <select value={imposto} onChange={handleImpostoChange}>
                <option value="Teresina">Teresina (30%)</option>
                <option value="viagem">Viagem (25%)</option>
                <option value="viagem2">Viagem2 (20%)</option>
              </select>
            </div>

            <div>
              <label>A Receber</label>
              <input type="text" value={aReceber} onChange={(e) => setAReceber(e.target.value)} readOnly />
            </div>

            <div>
              <label>Status</label>
              <select value={status} onChange={(e) => setStatus(e.target.value)} required >
                <option >Aceito</option>
                <option >Assinado</option>
                <option >Relatório</option>
                <option >Emitido</option>
                <option >Pago</option>
              </select>
            </div>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Formulario;
