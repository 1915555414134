import { useState } from "react";
import './register.css';
import Sidebar from '../../components/SideBar';
import LogoA from '../../assets/img/logoAzul.png'
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../auth/firebaseConnection";
import { collection, addDoc } from 'firebase/firestore';
import { toast } from "react-toastify";


function Home() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const navigate = useNavigate();

  async function novoUsuario(e) {
    e.preventDefault()

    if (email !== '' && senha !== '') {
      try {
        // Criar usuário no Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(auth, email, senha);

        // Adicionar documento dentro da coleção 'users' com ID automático
        const usersRef = collection(db, 'users');
        await addDoc(usersRef, { email: email }); // O ID do documento será gerado automaticamente

        toast.info("Cadastrado com sucesso", { className: 'toast-css' });
        navigate('/dashboard', { replace: true });
        setEmail('');
        setSenha('');
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          toast.warn("Email já cadastrado", { className: 'toast-css' })
        } else if (error.code === 'auth/invalid-email') {
          console.log("Digite um email válido", error)
          toast.warn("Digite um email válido", { className: 'toast-css' })
        } else if (error.code === 'auth/weak-password') {
          toast.warn("A senha deve ter no mínimo 6 caracteres", { className: 'toast-css' })
        } else {
          console.error('Erro ao cadastrar usuário:', error);
          toast.error("Erro ao cadastrar usuário", { className: 'toast-css' })
        }
      }
    } else {
      toast.warn('Preencha todos os campos', { className: 'toast-css' })
    }
  }

  return (
    <div>
      <Sidebar />
      <div className="container">

        <div className="content content-register">
            <h3>Faça seu cadastro para entrar</h3>
            <form className="register-form" onSubmit={novoUsuario}>
              <input
                type="text"
                placeholder='Usuário'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder='Senha'
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />

              <button type="subimit">CADASTRAR</button>
              <Link className='btn-logar' to='/'>
                LOGAR
              </Link>
            </form>
            
            <img className="logo-register" src={LogoA} alt="Logo"/>
          </div>
        </div>
      </div>
  );
}

export default Home;
