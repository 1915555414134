import React, { useState, useEffect } from "react";
import { auth } from "../auth/firebaseConnection";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";

export default function Private({ children, isUserMaster }) {
  const [loading, setLoading] = useState(true);
  const [logado, setLogado] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function checkLogin() {
      const unsub = onAuthStateChanged(auth, (user) => {
        if (user) {
          const userData = {
            uid: user.uid,
            email: user.email,
          };

          setUserData(userData);
          localStorage.setItem("accessToken", JSON.stringify(userData));

          setLoading(false);
          setLogado(true);
        } else {
          setLoading(false);
          setLogado(false);
          setUserData(null);
        }
      });

      return unsub;
    }

    checkLogin();
  }, []);


  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!logado) {
    return <Navigate to="/" />;
  }

  return children;
}
