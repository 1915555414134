import { useState } from "react";
import './login.css';
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../auth/firebaseConnection";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


function Home() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  async function logarUsuario(e) {
    e.preventDefault()

    if (email !== '' && senha !== '') {
      await signInWithEmailAndPassword(auth, email, senha)
        .then(() => {
          toast.success("Seja Bem-vindo!", { className: 'toast-css' })
          navigate('/dashboard', { replace: true })

          setEmail('')
          setSenha('')
        })
        .catch(() => {
          toast.error("ERRO AO LOGAR, Verifique seus dados", { className: 'toast-css' })
        })
    } else {
      toast.warn("Preencha todos os campos", { className: 'toast-css' });
    }

  }

  return (
    <div className="container-login">
      <img src="logoBranca.png" alt="Logo" className="logo" />

      <form className="login-form" onSubmit={logarUsuario}>
        <input
          type="text"
          placeholder='Seu email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder='Senha'
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="eye-icon"
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>

        <button type="submit">Entrar</button>
      </form>

      <Link className="btn-reset-password" to='/reset-password'>Esqueci minha senha </Link>
    </div>
  );
}

export default Home;
