import { getAuth } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../auth/firebaseConnection";

async function isUserMaster() {
  const auth = getAuth();
  const currentUserEmail = auth.currentUser ? auth.currentUser.email : null;

  try {
    // Buscar a coleção de userMasters no Firestore
    const userMastersCollection = collection(db, "userMasters");
    const userMastersSnapshot = await getDocs(userMastersCollection);

    // Obter os e-mails dos usuários mestres a partir dos dados do Firestore
    const userMasterEmails = userMastersSnapshot.docs.map((doc) => doc.data().email);

    // Verificar se o e-mail do usuário atual está na lista de e-mails dos usuários mestres
    const isMaster = userMasterEmails.includes(currentUserEmail);


    return isMaster;
  } catch (error) {
    console.error("Erro ao verificar usuário mestre:", error);
    return false; // Em caso de erro, considere como usuário não mestre
  }
}

export default isUserMaster;
