import { Routes, Route } from "react-router-dom";
import Login from '../pages/Login'
import ResetPassword from "../pages/Login/resetPassword";
import Register from '../pages/Register'
import Dashboard from "../pages/Dashboard";
import Atendimento from "../pages/Atendimento";
import Private from "./Private";
import Agendamento from "../pages/Agendamento";
import Demandas from "../pages/Demandas";
import NovaDemanda from "../pages/Demandas/novaDemanda";
import Contas from "../pages/Contas";
import isUserMaster from "./isUserMaster";

function RoutesApp() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="/dashboard" element={<Private> <Dashboard /> </Private>} />
      <Route path="/agendamento" element={<Private> <Agendamento /> </Private>} />
      <Route path="/contas" element={<Private> <Contas /> </Private>} />
      <Route path="/atendimento" element={<Private> <Atendimento /> </Private>} />
      <Route path="/demandas" element={<Private > <Demandas /> </Private>} />
      
      <Route path="/register" element={<Private isUserMaster={isUserMaster}> <Register /></Private>} />
      <Route path="/nova-demanda" element={<Private isUserMaster={isUserMaster}> <NovaDemanda /> </Private>} />
    </Routes>
  )
}


export default RoutesApp;