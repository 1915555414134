import { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar';
import './agendamento.css';
import { db } from '../../auth/firebaseConnection';
import { collection, query, where, addDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { onSnapshot } from 'firebase/firestore';
import { toast } from "react-toastify";



function Agendamento() {
  const [agendamentos, setAgendamentos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    hora1: '',
    hora2: '',
    usuario: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedDate) {
          const selectedDateFormatted = selectedDate.toLocaleDateString();
          const agendamentosRef = collection(db, 'agendamentos');
          const q = query(agendamentosRef, where('data', '==', selectedDateFormatted));

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => doc.data());

            data.sort((a, b) => a.hora1.localeCompare(b.hora1));

            // Verifica se os dados são diferentes antes de atualizar o estado
            if (!arraysAreEqual(agendamentos, data)) {
              setAgendamentos(data);
            }
          });

          return () => {
            unsubscribe();
          };
        }
      } catch (error) {
        toast.error('Erro ao buscar agendamentos', { className: 'toast-css' })
        console.log('Erro ao buscar agendamentos', error);
      }
    };

    fetchData();
  }, [selectedDate, agendamentos]);

  // Função para comparar arrays
  const arraysAreEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAgendamento = async (e) => {
    e.preventDefault();
    try {
      if (selectedDate) {
        const formDataWithDate = {
          ...formData,
          data: selectedDate.toLocaleDateString(),
        };

        if (!formData.hora1 || !formData.hora2 || !formData.usuario) {
          toast.warn('Preencha todos os campos obrigatórios', { className: 'toast-css' })
          return;
        }

        const agendamentoRef = collection(db, 'agendamentos');
        await addDoc(agendamentoRef, formDataWithDate);
        toast.success('Agendamento criado com sucesso!', { className: 'toast-css' });
        setFormData({
          hora1: '',
          hora2: '',
          usuario: ''
        });
      } else {
        toast.warn('Selecione uma data antes de agendar', { className: 'toast-css' });
      }
    } catch (error) {
      toast.error('Erro ao criar agendamento', { className: 'toast-css' });
      console.log('Erro ao criar agendamento', error);
    }
  };

  return (

    <div>
      <Sidebar />

      <div className='container'>

        <div className='content'>
          <h3>Data</h3>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            placeHolder="ererere"
            className="datepicker"
            placeholderText="Selecione data"
          />
        </div>
        <div className='content content-agendamento'>
          <h2>Agendamentos</h2>
          <table className="tableAgenda">
            <thead>
              <tr>
                <th>Data</th>
                <th>Hora incial</th>
                <th>Hora final</th>
                <th>Usuário</th>
              </tr>
            </thead>
            <tbody>
              {agendamentos.map((agendamento, index) => (
                <tr key={index}>
                  <td>{agendamento.data}</td>
                  <td>{agendamento.hora1}</td>
                  <td>{agendamento.hora2}</td>
                  <td>{agendamento.usuario}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='content'>
          <h2>Novo Agendamento</h2>
          <form className='form-agendamento'>
            <div className='form-group'>
              <label>Início:</label>
              <select
                name="hora1"
                value={formData.hora1}
                onChange={handleInputChange}
                placeholder="Selecione a hora de início"
              >
                <option value="Selcione">Selcecione</option>
                <option value="8:00">8:00</option>
                <option value="8:30">8:30</option>
                <option value="9:00">9:00</option>
                <option value="9:30">9:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="13:00">13:00</option>
                <option value="13:30">13:30</option>
                <option value="14:00">14:00</option>
                <option value="14:30">14:30</option>
                <option value="15:00">15:00</option>
                <option value="15:30">15:30</option>
                <option value="16:00">16:00</option>
                <option value="16:30">16:30</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Término:</label>
              <select
                name="hora2"
                value={formData.hora2}
                onChange={handleInputChange}
                placeholder="Selecione a hora de início"
              >
                <option value="Selcione">Selcecione</option>
                <option value="9:00">9:00</option>
                <option value="9:30">9:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="12:00">12:00</option>
                <option value="12:30">12:30</option>
                <option value="13:00">13:00</option>
                <option value="13:30">13:30</option>
                <option value="14:00">14:00</option>
                <option value="14:30">14:30</option>
                <option value="15:00">15:00</option>
                <option value="15:30">15:30</option>
                <option value="16:00">16:00</option>
                <option value="16:30">16:30</option>
                <option value="17:00">17:00</option>
                <option value="17:30">17:30</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Consultor:</label>
              <input
                type='text'
                name='usuario'
                value={formData.usuario}
                onChange={handleInputChange}
                placeholder='Digite seu nome'
              />
            </div>
            <button className='btn-agendar' onClick={handleAgendamento}>
              Agendar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default Agendamento;
