import React from 'react';
import './paginacao.css'

const Pagination = ({ currentPage, itemsPerPage, totalItems, onNextPage, onPrevPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="pagination">
      <button onClick={onPrevPage} disabled={currentPage === 1}> ← </button>
      <span>{currentPage}</span>
      <span> / </span>
      <span>{totalPages}</span>
      <button onClick={onNextPage} disabled={currentPage >= totalPages}> → </button>
    </div>
  );
};

export default Pagination;
