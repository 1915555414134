import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { db, auth } from '../../auth/firebaseConnection'
import { collection, getDocs } from 'firebase/firestore';
import isUserMaster from '../../routes/isUserMaster';
import { toast } from 'react-toastify';


function GraficoDemandas() {
  const [demandasData, setDemandasData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;

        if (user) {
          const isMaster = await isUserMaster();
          const demandasRef = collection(db, 'demandas');
          const demandasSnapshot = await getDocs(demandasRef);

          const demandasData = [];

          await Promise.all(demandasSnapshot.docs.map(async (demandaDoc) => {
            const subcolecoesRef = collection(demandaDoc.ref, 'subcolecoes');
            const subcolecoesSnapshot = await getDocs(subcolecoesRef);

            subcolecoesSnapshot.forEach((subcolecaoDoc) => {
              const data = {
                id: subcolecaoDoc.id,
                email: demandaDoc.id,
                status: subcolecaoDoc.data().status,
              };

              if (isMaster || user.email === data.email) {
                demandasData.push(data);
              }
            });
          }));

          setDemandasData(demandasData);
        }
      } catch (error) {
        toast.error('Erro ao buscar demandas');
      }
    };

    fetchData();
  }, []);

  // Lógica para renderizar gráficos
  useEffect(() => {
    const renderizarGraficoDemandas = () => {
      if (demandasData.length === 0) return;

      const statusCounts = {};
      demandasData.forEach((demanda) => {
        if (statusCounts[demanda.status]) {
          statusCounts[demanda.status]++;
        } else {
          statusCounts[demanda.status] = 1;
        }
      });

      const labels = Object.keys(statusCounts);
      const data = Object.values(statusCounts);

      const dadosGrafico = {
        labels: labels,
        datasets: [{
          label: 'Demandas por Status',
          data: data,
          backgroundColor: [
            'rgba(153, 102, 255, 1)',
            '#4deacb',
            '#06109a',
            '#647fff',
            'rgba(255, 206, 86, 1)',
          ],
        }]
      };

      // Configurações para o gráfico
      const configGrafico = {
        type: 'doughnut',
        data: dadosGrafico,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };

      const canvasGrafico = document.getElementById('demandasPorStatusChart');

      // Verifica se o elemento do gráfico ainda existe antes de acessá-lo
      if (canvasGrafico) {
        let graficoAnterior = canvasGrafico.chart;

        if (graficoAnterior) {
          graficoAnterior.destroy();
        }

        canvasGrafico.chart = new Chart(canvasGrafico, configGrafico);
      }
    };

    renderizarGraficoDemandas();

    return () => {
      const canvasGrafico = document.getElementById('demandasPorStatusChart');
      let graficoAnterior = canvasGrafico ? canvasGrafico.chart : null;

      if (graficoAnterior) {
        graficoAnterior.destroy();
      }
    };
  }, [demandasData]);


  return (
    <div>
      <canvas id="demandasPorStatusChart"></canvas>
    </div>
  );
}

export default GraficoDemandas;
