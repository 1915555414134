import { useState } from 'react';
import { auth } from '../../auth/firebaseConnection';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Link para redefinir senha enviado para seu email", { className: 'toast-css' })
    } catch (error) {
      toast.error("Erro! Verifique seu email", { className: 'toast-css' })
    }
  }

  return (
    <div className='container-login'>
      <div className='login-form'>
        <h2>Redefinir Senha</h2>
        <form onSubmit={handleResetPassword}>
          <div className='input-group'>
            <label>Email:</label>
            <input
            type='email'
            placeholder='Seu email cadastrado'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
          </div>
          <button type='submi'>Enviar Link de Redefinir Senha</button>
        </form>

        <Link to="/">Voltar para Login</Link>
      </div>
    </div>
  )
}

export default ResetPassword
