import React, { useState, useEffect } from 'react'
import './contas.css'
import Sidebar from '../../components/SideBar';
import Modal from '../../components/Modal';
import Pagination from '../../components/Paginacao';
import { deleteDoc, addDoc, collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from "../../auth/firebaseConnection";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

function Contas() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConta, setSelectedConta] = useState(null);

  const [contas, setContas] = useState([]);
  const [contasOriginais, setContasOriginais] = useState([]);
  const [filtroDataInicio, setFiltroDataInicio] = useState(null);
  const [filtroDataFinal, setFiltroDataFinal] = useState(null);
  const [filtroStatus, setFiltroStatus] = useState('Todas');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [novaConta, setNovaConta] = useState({
    descricao: '',
    valor: '',
    vencimento: '',
    status: 'A vencer',
  });

  useEffect(() => {
    // Verifica se a coleção 'contas' existe, senão a cria
    const verificarColecao = async () => {
      const colecaoQuery = collection(db, 'contas');
      const colecaoSnap = await getDocs(colecaoQuery);

      if (colecaoSnap.empty) {
        // A coleção 'contas' não existe, então criamos
        await addDoc(colecaoQuery, { dummy: 'dummy' });
      }
    };

    // Função para buscar as contas no banco de dados
    const buscarContas = async () => {
      try {
        const user = auth.currentUser;

        if (user) {
          const contasQuery = query(collection(db, 'contas'), where('userId', '==', user.uid));
          const contasSnapshot = await getDocs(contasQuery);

          const contasData = contasSnapshot.docs.map(doc => {
            const data = { id: doc.id, ...doc.data() };

            // Adicione a verificação e atualização do status aqui
            if (data.vencimento && new Date(data.vencimento) <= new Date() && data.status !== 'Pago') {
              data.status = 'Vencida';

              // Agora, também atualize o status no banco de dados
              updateDoc(doc.ref, { status: 'Vencida' });
            }

            return data;
          });

          //Ordena as contas com base na data de venimento
          contasData.sort((a, b) => new Date(a.vencimento) - new Date(b.vencimento));

          setContas(contasData);
          setContasOriginais(contasData);
        }
      } catch (error) {
        console.error('Erro ao buscar contas:', error);
        toast.error('Erro ao buscar contas', { className: 'toast-css' });
      }
    };


    buscarContas();
    verificarColecao();
  }, []);

  // Lógica para adicionar uma nova conta
  const adicionarConta = async (event) => {
    event.preventDefault();

    if (!novaConta.descricao || !novaConta.valor || !novaConta.vencimento) {
      toast.warning('Preencha todos os campos.', { className: 'toast-css' });
      return;
    }

    try {
      const user = auth.currentUser;
      const contasRef = collection(db, 'contas');

      const novaContaComId = { ...novaConta, userId: user.uid };

      const docRef = await addDoc(contasRef, novaContaComId);

      // Captura o ID gerado automaticamente e atualiza o campo 'id' no documento
      await updateDoc(docRef, { id: docRef.id });

      toast.success('Cadastrada com sucesso!', { className: 'toast-css' });

      setContasOriginais((prevContas) => [...prevContas, { ...novaContaComId, id: docRef.id }]);
      setContas((prevContas) => [...prevContas, { ...novaContaComId, id: docRef.id }]);

      setNovaConta({
        descricao: '',
        valor: '',
        vencimento: '',
        status: 'A vencer',
      });
    } catch (error) {
      console.error('Erro ao adicionar conta:', error);
      toast.error('Erro ao adicionar', { className: 'toast-css' });
    }
  };


  // Lógica para filtrar as contas
  const filtrarContas = () => {
    const contasOriginaisCopia = [...contasOriginais];

    const contasFiltradas = contasOriginaisCopia.filter((conta) => {
      if (filtroDataInicio && filtroDataFinal) {
        const dataVencimento = new Date(conta.vencimento);
        if (!(dataVencimento >= filtroDataInicio && dataVencimento <= filtroDataFinal)) {
          return false;
        }
      }

      if (filtroStatus !== 'Todas') {
        return conta.status === filtroStatus;
      }

      return true;
    });


    setFiltroDataInicio(null);
    setFiltroDataFinal(null);
    setFiltroStatus('Todas');

    setContas(contasFiltradas);
  };

  // Lógica para definir a data de início do filtro
  const handleDataInicioChange = (date) => {
    setFiltroDataInicio(date);
  };

  // Lógica para definir a data final do filtro
  const handleDataFinalChange = (date) => {
    setFiltroDataFinal(date);
  };

  // Lógica para definir o status do filtro
  const handleStatusChange = (e) => {
    setFiltroStatus(e.target.value);
  };

  // Lógica para calcular o valor total das contas
  const calcularValorTotal = () => {
    return contas.reduce((total, conta) => total + parseFloat(conta.valor), 0);
  };

  // Funções de paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = contas.slice(indexOfFirstItem, indexOfLastItem);

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Função para atualizar os dados da conta no Firestore
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const { uid } = selectedConta;
      const contaRef = doc(db, 'contas', uid);

      const updatedData = {
        descricao: selectedConta.descricao,
        valor: selectedConta.valor,
        vencimento: selectedConta.vencimento,
        status: selectedConta.status,
      };

      // Adicione a lógica de atualização do status visualmente e no banco de dados
      if (updatedData.vencimento && new Date(updatedData.vencimento) <= new Date() && updatedData.status !== 'Pago') {
        updatedData.status = 'Vencida';
        updateDoc(contaRef, { status: 'Vencida' });
      }

      await updateDoc(contaRef, updatedData);

      toast.success('Conta atualizada com sucesso!', { className: 'toast-css' });

      // Atualize o estado local para refletir as alterações na interface
      setContas((prevContas) =>
        prevContas.map((conta) => (conta.id === uid ? { ...conta, ...updatedData } : conta))
      );

      closeModal();  // Feche o modal após a atualização
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      toast.error('Erro ao salvar dados', { className: 'toast-css' });
    }
  };

  // Função para manipular a alteração de entrada nos campos do modal
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setSelectedConta((prevConta) => ({
      ...prevConta,
      [fieldName]: value,
    }));
  };

  // Função para abrir o modal com os dados da conta selecionada
  const handleEditClick = (conta) => {
    const uid = conta.uid || conta.id;

    if (!uid) {
      console.error('Nenhuma conta selecionada para editar. UID não definido.');
      return;
    }

    const contaComUid = { ...conta, uid };
    setSelectedConta(contaComUid);
    setIsModalOpen(true);
  };

  // Função para Excluir conta
  const handleDeleteClick = (conta) => {
    const confirmarExclusao = window.confirm('Tem certeza que deseja excluir esta conta?');

    if (confirmarExclusao) {
      const uid = conta.uid || conta.id;

      if (!uid) {
        console.error('Nenhuma conta selecionada para excluir. UID não definido.');
        return;
      }

      excluirConta(uid);
    }
  };

  const excluirConta = async (uid) => {
    try {
      const contaRef = doc(db, 'contas', uid);
      await deleteDoc(contaRef);

      toast.success('Conta excluída com sucesso!', { className: 'toast-css' });

      setContas((prevContas) => prevContas.filter((conta) => conta.id !== uid));
    } catch (error) {
      console.error('Erro ao excluir conta:', error);
      toast.error('Erro ao excluir conta', { className: 'toast-css' });
    }
  };

  // Fecha o modal
  const closeModal = () => {
    setSelectedConta(null);
    setIsModalOpen(false);
  };;

  return (
    <>
      <Sidebar />
      <div className='container'>
        <div className="content">
          <h2> Contas a pagar</h2>
          <div className="contas">
            {/* Incluir contas */}
            <div className=''>
              <form className='form-contas'>
                <div className='form-group'>
                  <label>Descrição</label>
                  <input
                    type='text'
                    name='descricao'
                    value={novaConta.descricao}
                    onChange={(e) => setNovaConta({ ...novaConta, descricao: e.target.value })}
                    placeholder='Digite descrição da conta'
                    required />
                </div>
                <div className='form-group'>
                  <label>Valor</label>
                  <input
                    type='number'
                    name='valor'
                    value={novaConta.valor}
                    onChange={(e) => setNovaConta({ ...novaConta, valor: e.target.value })}
                    placeholder='R$'
                    required />
                </div>
                <div className='form-group'>
                  <label>Vencimento</label>
                  <input
                    type='date'
                    name='vencimento'
                    value={novaConta.vencimento}
                    onChange={(e) => setNovaConta({ ...novaConta, vencimento: e.target.value })}
                    placeholder='Digite data de vencimento'
                    required />
                </div>
                <button type="button" onClick={adicionarConta}>Adicionar conta</button>
              </form>

              {/* mostra o valor total do filtro */}
              <div className='valorTotal'>
                <div>
                  <h3>Valor Total</h3>
                  <p>Valor referente ao filtro selecionado</p>

                </div>
                <span>R$ {calcularValorTotal().toFixed(2)}</span>
              </div>
            </div>

            {/* Mostrar contas */}
            <div className='div-right'>
              <h5>Filtrar</h5>
              <div className="custom-datepicker">
                <DatePicker
                  className="input-date-picker"
                  placeholderText="Data inicio"
                  dateFormat="dd/MM/yyyy"
                  selected={filtroDataInicio}
                  onChange={handleDataInicioChange}
                />
                <DatePicker
                  className="input-date-picker"
                  placeholderText="Data final"
                  dateFormat="dd/MM/yyyy"
                  selected={filtroDataFinal}
                  onChange={handleDataFinalChange}
                />
              </div>
              <select value={filtroStatus} onChange={handleStatusChange}>
                <option>Todas</option>
                <option>A vencer</option>
                <option>Vencida</option>
                <option>Pago</option>
              </select>
              <button onClick={filtrarContas}>Filtrar</button>

              <div className='lista-contas'>
                <table>
                  <thead>
                    <tr>
                      <th>Conta</th>
                      <th>Vencimento</th>
                      <th>Status</th>
                      <th>Valor</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((conta, id) => (
                      <tr key={id}>
                        <td>{conta.descricao}</td>
                        <td>{conta.vencimento}</td>
                        <td className={conta.status === 'Vencida' ? 'vencida' : (conta.status === 'Pago' ? 'paga' : '')}>{conta.status}</td>
                        <td>R$ {parseFloat(conta.valor).toFixed(2)}</td>
                        <td className="btn-edit" onClick={() => handleEditClick(conta)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </td>
                        <td className="btn-del" onClick={() => handleDeleteClick(conta)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Adicione o componente de paginação */}
              <div className="pagination">
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={contas.length}
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
          <form className='form-contas'>
            <div className='form-group'>
              <label>Descrição</label>
              <input
                type='text'
                name='descricao'
                value={selectedConta ? selectedConta.descricao : ''}
                onChange={(e) => handleInputChange(e, 'descricao')}
              />
            </div>
            <div className='form-group'>
              <label>Valor</label>
              <input
                type='number'
                name='valor'
                value={selectedConta ? selectedConta.valor : ''}
                onChange={(e) => handleInputChange(e, 'valor')}
              />
            </div>
            <div className='form-group'>
              <label>Vencimento</label>
              <input
                type='date'
                name='vencimento'
                value={selectedConta ? selectedConta.vencimento : ''}
                onChange={(e) => handleInputChange(e, 'vencimento')}
              />
            </div>
            <div className='form-group'>
              <label>Status</label>
              <select
                name='status'
                value={selectedConta?.status}
                onChange={(e) => handleInputChange(e, 'status')}
              >
                <option>Selecione</option>
                <option>A vencer</option>
                <option>Vencida</option>
                <option>Pago</option>
              </select>
            </div>
            <button onClick={(e) => handleSave(e)}>Salvar</button>
          </form>
        </Modal>

      </div>
    </>
  )
}

export default Contas
