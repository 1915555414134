import React, { useEffect, useState } from 'react'
import { auth, db } from '../../auth/firebaseConnection';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Chart from 'chart.js/auto';
import { toast } from 'react-toastify';

const GraficoContas = () => {
  const [contas, setContas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        const contasRef = collection(db, 'contas');
        const contasQuery = query(contasRef, where('userId', '==', user.uid));

        const contasSnapshot = await getDocs(contasQuery);
        const contasData = contasSnapshot.docs.map((doc) => doc.data());

        setContas(contasData);
      }
      catch (error) {
        toast.error('Erro ao buscar contas');
      }
    };

    fetchData();
  }, []);

  // Lógica para renderizar gráficos
  useEffect(() => {
    const renderizarGraficos = () => {
      // Filtra as contas do mês vigente
      const contasMesVigente = contas.filter((conta) => {
        const dataVencimento = conta.vencimento ? new Date(conta.vencimento) : null;

        if (dataVencimento) {
          const dataAtual = new Date();
          const mesAtual = dataAtual.getMonth();
          const anoAtual = dataAtual.getFullYear();

          return (
            dataVencimento.getMonth() === mesAtual &&
            dataVencimento.getFullYear() === anoAtual
          );
        }

        return false;
      });

      const dadosContasMesVigente = {
        labels: ['A Pagar', 'Vencidas', 'Pagas'],
        datasets: [{
          label: 'Contas do Mês',
          data: [
            contasMesVigente.filter((conta) => conta.status === 'A vencer').length,
            contasMesVigente.filter((conta) => conta.status === 'Vencida').length,
            contasMesVigente.filter((conta) => conta.status === 'Pago').length,
          ],
          backgroundColor: ['#06109a', '#fe4900', '#4deacb'],
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1,
          barThickness: 100,
        }],
      };

      // Configurações para o gráfico
      const configContasMesVigente = {
        type: 'bar',
        data: dadosContasMesVigente,
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      };

      const canvasContasMesVigente = document.getElementById('contasMesVigente');

      // Verifica se já existe um gráfico no elemento canvas
      if (canvasContasMesVigente.chart) {
        canvasContasMesVigente.chart.destroy();
      }

      // Cria um novo gráfico no elemento canvas
      canvasContasMesVigente.height = 300;
      canvasContasMesVigente.chart = new Chart(canvasContasMesVigente, configContasMesVigente);
    };

    renderizarGraficos();
  }, [contas]);

  return (
    <div>
      <canvas id="contasMesVigente"></canvas>
    </div>
  )
}

export default GraficoContas
