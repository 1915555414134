import { useState } from 'react';
import { PDFViewer, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Sidebar from '../../components/SideBar';
import './atendimento.css';
import Logo from '../../assets/img/logoAzul.png';


function StepTitle({ title }) {
  return (
    <View style={{ width: '100%', textAlign: 'center' }}>
      <Text style={{ fontSize: 20, marginBottom: 10 }}>{title}</Text>
    </View>
  );
}

function Atendimento() {
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Data, setStep1Data] = useState({ label1: '' });
  const [step2Data, setStep2Data] = useState({ label2: '' });
  const [step3Data, setStep3Data] = useState({ label3: '' });
  const [step4Data, setStep4Data] = useState({ label4: '' });
  const [step5Data, setStep5Data] = useState({ label5: '' });
  const [step6Data, setStep6Data] = useState({ label6: '' });

  const NextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const PreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Steps
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <div>
          <label htmlFor="label1">Ganhos/Avanços</label>
          <textarea
            id="label1"
            rows="4"
            cols="50"
            value={step1Data.label1}
            onChange={(e) => setStep1Data({ ...step1Data, label1: e.target.value })}
          />
        </div>;
      case 2:
        return <div>
          <label htmlFor="label2">Levantamento Atual</label>
          <textarea
            id="label2"
            rows="4"
            cols="50"
            value={step2Data.label2}
            onChange={(e) => setStep2Data({ ...step2Data, label2: e.target.value })}
          />
        </div>;
      case 3:
        return <div>
          <label htmlFor="label3">Atividade de Sala:</label>
          <textarea
            id="label3"
            rows="4"
            cols="50"
            value={step3Data.label3}
            onChange={(e) => setStep3Data({ ...step3Data, label3: e.target.value })}
          />
        </div>;
      case 4:
        return <div>
          <label htmlFor="label4">Atividade de Casa:</label>
          <textarea
            id="label4"
            rows="4"
            cols="50"
            value={step4Data.label4}
            onChange={(e) => setStep4Data({ ...step4Data, label4: e.target.value })}
          />
        </div>;
      case 5:
        return <div>
          <label htmlFor="label5">Complemento 1:</label>
          <textarea
            id="label5"
            rows="4"
            cols="50"
            value={step5Data.label5}
            onChange={(e) => setStep5Data({ ...step5Data, label5: e.target.value })}
          />
        </div>;
      case 6:
        return <div>
          <label htmlFor="label6">Complemento 2:</label>
          <textarea
            id="label6"
            rows="4"
            cols="50"
            value={step6Data.label6}
            onChange={(e) => setStep6Data({ ...step6Data, label6: e.target.value })}
          />
        </div>;
      default:
    }
  };

  return (

    <div>
      <Sidebar />
      <div className="container">
        <div className="content">
          <h2>Novo Atendimento</h2>
          <div className='btn-container'>
            {currentStep > 1 && (
              <button onClick={PreviousStep}>Voltar</button>
            )}
            {currentStep < 7 && (
              <button className='btn-avancar' onClick={NextStep}>Avançar</button>
            )}
          </div>

          {renderStep()}

          {currentStep === 7 && (
            <div>
              <PDFViewer width="100%" height="600px">
                <Document>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <Image src={Logo} style={{ width: 60, marginBottom: 10 }} />
                    <View>
                      <StepTitle title={'GANHOS E AVANÇOS'} />
                      <Text>{step1Data.label1}</Text>
                    </View>
                  </Page>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <View>
                      <StepTitle title={'LEVANTAMENTO ATUAL'} />
                      <Text>{step2Data.label2}</Text>
                    </View>
                  </Page>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <View>
                      <StepTitle title={'ATIVIDADE DE SALA'} />
                      <Text>{step3Data.label3}</Text>
                    </View>
                  </Page>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <View>
                      <StepTitle title={'ATIVIDADE DE CASA'} />
                      <Text>{step4Data.label4}</Text>
                    </View>
                  </Page>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <View>
                      <StepTitle title={'COMPLEMENTO 1'} />
                      <Text>{step5Data.label5}</Text>
                    </View>
                  </Page>
                  <Page size="A4" style={{ padding: '30px' }}>
                    <View>
                      <StepTitle title={'COMPLEMENTO 2'} />
                      <Text>{step6Data.label6}</Text>
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Atendimento;
